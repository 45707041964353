<template>
  <div class="movement-22">
    <img
      v-if="!claimedAll"
      src="@/assets/images/events/movement-22/movement_logo.png"
      alt="Movement 22"
      class="event-image"
    >
    <img
      v-else
      src="@/assets/images/events/movement-22/movement_logo_color.png"
      alt="Movement 22 in Color"
      class="event-image"
    >

    <div class="event-title">
      <h1>Sheefy's</h1>
      <img src="@/assets/images/events/movement-22/ghettotechtopia_title.png" alt="Ghettotechtopia">
      <h1>NFT Scavenger Hunt</h1>
    </div>

    <h2>Help Sheefy Find His Gear!</h2>
    <p>The Detroit-based fine artist, rapper and producer has lost his MPC controller, synthesizer, turntable, and four of his mates. Can you help Sheefy find them in time for his DJ set?</p>
    <p>Go to the Pyramid Amphitheatre (1XRUN &amp; OOZE Artist-in-Residence area) to discover the hidden QR codes and scan each one to claim a free NFT. Claim all seven to download the Ghettotechtopia EP, win a custom work of art, and save the day.</p>

    <h2>Collect Them All</h2>
    <p>The seven missing tokens are pictured here to aid you on your quest. As you claim your NFTs, they'll light up in color on your screen below!</p>
    <p>Once you've logged in and minted your tokens, view them in your portfolio to listen to full tracks from the Ghettotechtopia EP!</p>
    <div class="sticker-gallery">
      <div
        v-for="(data, index) in eventDataFormatted"
        :key="`sticker-${index}`"
        class="sticker"
        :class="{ placeholder: !data.collection || !data.featuredType }"
      >
        <template v-if="data.collection && data.featuredType">
          <component v-if="data.claimed" :is="currentUser ? 'router-link' : 'span'" to="/portfolio">
            <img
              :src="tokenImagePath(data.collection, data.featuredType, 320, true)"
              :alt="data.name"
            >
          </component>
          <img
            v-else
            :src="tokenImagePathUnclaimed(data.collection, data.featuredType, 320)"
            :alt="data.name"
          >
        </template>
      </div>
      <LoaderCover v-if="loading" transparent-full center />
    </div>

    <h2>Pro Tip</h2>
    <p><span class="login-link" @click="setShowingLogin(true)">Sign up</span> for a free account now to speed up the claiming process while you explore the event.</p>

    <img
      src="@/assets/images/events/movement-22/movement_map.jpg"
      alt="Map of the Movement 22 Music Festival"
      class="map"
    >

    <div class="movement-about">
      <img src="@/assets/images/events/movement-22/movement_festival_logo.svg" alt="Movement">
      <p>Movement Music Festival is one of the longest-running dance music events in the world, committed to showcasing authentic electronic music and providing an experience unlike any other. The festival takes place in Hart Plaza - Detroit's legendary riverfront destination.</p>
      <p>
        <a href="https://www.movementfestival.com/" target="_blank">Get Passes Now</a>
      </p>
    </div>

    <img
      src="@/assets/images/events/movement-22/movement_22_banner.jpg"
      alt="Movement 22"
      class="banner"
    >

    <div class="artist-about">
      <h2>About the Artist</h2>
      <img :src="artistImage" alt="Sheefy McFly">
      <h3>Sheefy McFly</h3>
      <h4>Movement Artist-In-Residence</h4>
      <div class="socials">
        <a href="https://www.instagram.com/sheefymcfly" target="_blank">
          <v-icon>mdi-instagram</v-icon>
        </a>
        <a href="https://twitter.com/sheefymcfly" target="_blank">
          <v-icon>mdi-twitter</v-icon>
        </a>
      </div>
      <p>Multidisciplinary artist Sheefy McFly represents his hometown in many ways. The painter, rapper, producer, DJ, &amp; muralist is heavily influenced by the Motor City hustle and sound. Sheefy's installation for Movement 2022 is a vibrant ode to Detroit dance parties, from the DJ booth to the dancefloor.</p>
    </div>

    <PresentedBy />
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import PresentedBy from '@/components/movement-22/PresentedBy'
export default {
  components: {
    PresentedBy
  },
  async mounted () {
    this.loading = true
    this.eventDataFormatted = this.targetPromos
    await this.getFeaturedEventData(this.targetPromos)
    this.eventData = JSON.parse(JSON.stringify(this.featuredEventData))
    await this.userInit()
    this.loading = false
  },
  data () {
    return {
      loading: false,
      targetPromos: [
        'm22-banjo-jit',
        'm22-soda-pop',
        'm22-glissen',
        'm22-popdroptwerkshake',
        'm22-late-nite-jit-rough',
        'm22-bottle-top-rough',
        'm22-midnight-durt'
      ],
      promoOwnership: {},
      targetEmail: null,
      eventData: {},
      eventDataFormatted: [],
      claimedAll: false
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'featuredEventData']),
    artistImage () {
      return `${process.env.VUE_APP_CLOUDINARY_ROOT}/image/upload/w_540/stores/onexrun/collection-groups/sheefy-mcfly/image.jpg`
    }
  },
  methods: {
    ...mapMutations(['setShowingLogin']),
    ...mapActions(['getFeaturedEventData', 'userAuthCheck']),

    async userInit () {
      console.log('Initializing page for user.')
      if (this.currentUser) {
        console.log('Using currentUser email.')
        this.targetEmail = this.currentUser.email
      } else {
        console.log('Using localStorage email.')
        this.targetEmail = window.localStorage.getItem('claimEmail')
      }
      if (this.targetEmail) {
        await this.checkOwnership()
      }
      this.formatEventData()
      this.checkClaimedAll()
      this.checkForBonus()
    },

    async checkOwnership () {
      for (let i = 0; i < this.targetPromos.length; i++) {
        const targetPromo = this.targetPromos[i]
        this.promoOwnership[targetPromo] = await this.checkPromotionClaimOwnership(targetPromo, this.targetEmail)
      }
    },

    formatEventData () {
      const formatted = []
      for (const promo in this.eventData) {
        const promoData = this.eventData[promo]
        promoData.claimed = this.promoOwnership[promo]
        formatted.push(this.eventData[promo])
      }
      this.eventDataFormatted = formatted
    },

    checkClaimedAll () {
      let numClaimed = 0
      this.eventDataFormatted.forEach((item) => {
        if (item.claimed) {
          numClaimed++
        }
      })
      this.claimedAll = numClaimed === this.targetPromos.length
    },

    async checkForBonus () {
      console.log('Checking for bonus NFT.')
      if (this.claimedAll) {
        console.log('The user has claimed all promotions.')
        try {
          const res = await this.fbCall('promotions-checkForUser', {
            targetPromo: 'm22-ghettotechtopia-bonus',
            targetEmail: this.targetEmail
          })
          console.log(res.data)
        } catch (err) {
          console.log(err)
        }
      }
    }
  },

  watch: {
    currentUser (oldUser, newUser) {
      if (!oldUser?.email && newUser?.email) {
        this.userInit()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.movement-22 {
  padding: $space-xl;

  .event-image {
    width: 100%;
  }
  .event-title {
    text-align: center;
    margin: $space-xl 0;
    h1 {
      font-size: $font-size-l;
      line-height: 1em;
      color: #8a35ff;

      @include media($bp-tablet) {
        font-size: $font-size-ml;
      }
    }
    img {
      width: 100%;
    }
  }

  h2 {
    margin: $space-l 0 $space-m;
  }

  p {
    font-family: $font-title;
    font-size: $font-size-ml;
    margin-bottom: $space-m;
    .login-link {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  @include media($bp-tablet) {
    padding: 0;
    h1 {
      font-size: $font-size-l;
      line-height: 1em;
    }
    h2 {
      font-size: $font-size-ml;
    }
    p {
      font-size: $font-size-m;
    }
  }
}
.sticker-gallery {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: $space-s;
  grid-column-gap: $space-s;
  text-align: center;
  margin: $space-l 0;

  @include media($bp-tablet) {
    grid-template-columns: 1fr 1fr;
  }

  .sticker {
    font-size: 0px;
    &.placeholder {
      padding-bottom: 100%;
      background-color: $blk-grey-6;
    }
    img {
      width: 100%;
    }
  }
}
.banner,
.map {
  width: 100%;
}
.movement-about {
  margin: $space-xxl 0;
  img {
    margin-bottom: $space-ml;
    max-width: 100%;
  }
  p {
    margin-bottom: $space-ml;
  }
}
.artist-about {
  margin: $space-l 0 $space-xxl;
  text-align: center;

  img {
    width: 100%;
    max-width: 540px;
  }
  h2 {
    margin-bottom: $space-l;
  }
  h3 {
    margin: $space-l 0 $space-s;
  }
  h4 {
    margin-bottom: $space-l;
  }
  .socials {
    a {
      text-decoration: none;
    }
    .v-icon {
      font-size: 20px;
      margin: 0 $space-xs;
    }
  }
  p {
    margin: $space-ml 0;
  }
}
</style>
